import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        {/* <path
          d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          fill="currentColor"
        /> */}
        <path fill="#64ffda" stroke="#64ffda" d="M143.5 3.574c-3.3 1.857-6.9 3.841-8 4.408-1.1.567-4.7 2.609-8 4.537s-6.9 3.95-8 4.491c-1.1.542-2.675 1.413-3.5 1.936C112.805 20.97 96.918 30 96.552 30 96.156 30 81.705 38.258 79 40.03a27.599 27.599 0 0 1-3 1.669c-.825.377-6.363 3.524-12.308 6.993C57.748 52.162 52.723 55 52.525 55c-.333 0-14.131 7.862-17.525 9.986-.825.516-3.471 1.964-5.879 3.218-5.695 2.964-10.71 8.894-11.485 13.579-.826 5-.789 138.658.04 143.68.69 4.179 7.859 12.506 12.537 14.564 3.212 1.413 5.747 2.896 47.506 27.788C98.19 280.017 115.403 290 115.969 290c.567 0 1.031.406 1.031.902s1.238 1.398 2.75 2.003c1.513.606 4.1 1.993 5.75 3.083 1.65 1.09 3.9 2.446 5 3.012 1.1.566 3.35 1.916 5 3s3.9 2.451 5 3.04c1.1.588 3.575 1.923 5.5 2.966 7.113 3.857 14.068 2.322 27.5-6.068 1.65-1.031 4.35-2.543 6-3.36 1.65-.818 5.25-2.849 8-4.515 2.75-1.666 5.944-3.449 7.097-3.963 1.153-.514 5.159-2.809 8.903-5.1 3.744-2.291 7.75-4.584 8.903-5.095 1.153-.512 3.672-1.878 5.597-3.038 1.925-1.159 5.525-3.21 8-4.557 2.475-1.348 5.85-3.304 7.5-4.348 1.65-1.044 4.35-2.567 6-3.384 1.65-.818 5.25-2.842 8-4.498 2.75-1.657 6.35-3.689 8-4.516 1.65-.827 5.7-3.066 9-4.975 3.3-1.909 7.673-4.383 9.718-5.497 7.278-3.967 11.562-6.997 13.657-9.661 3.109-3.952 3.374-151.877.282-156.931-1.513-2.472-56.115-36.5-58.569-36.5-.268 0-2.423-1.297-4.788-2.882-2.365-1.585-5.875-3.737-7.8-4.783-1.925-1.045-5.427-3.138-7.782-4.651-2.355-1.512-6.18-3.723-8.5-4.913-2.32-1.189-4.452-2.497-4.738-2.904-.287-.408-1.637-1.227-3-1.819-1.364-.593-3.83-1.964-5.48-3.048-1.65-1.084-3.9-2.434-5-3-1.1-.566-3.35-1.916-5-3s-3.9-2.434-5-3c-1.1-.566-3.35-1.909-5-2.983-9.329-6.074-15.149-6.424-24-1.443" fill-rule="evenodd"/>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
