import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['JavaScript (ES6+)', 'TypeScript', 'React', 'Next.js', 'Node.js', 'WordPress', 'Screaming Frog', 'GA4', 'Tag Manager', 'ahrefs', 'SEMRush', 'Search Console', 'Looker Studio', 'App Script'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello there! I'm Gannes, and I have a deep passion for bringing ideas to life in the digital world. 
              My journey in digital marketing kicked off in 2013 when I first delved into customizing Blogger themes. 
              Tinkering with HTML & CSS became my playground, and my first creation was a custom reblog button that fueled my curiosity and growth.
            </p>

            <p>
              Fast forward to today, I've had the privilege of working with leading brands like {' '}
              <a href="https://www.agsgroup.co.id/?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank" rel="noreferrer">Airmas Golden Solusindo</a>,{' '}
              <a href="https://www.airmasgroup.co.id/?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank" rel="noreferrer">Airmas Perkasa</a>,{' '}
              <a href="https://www.kalcare.com/?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank" rel="noreferrer">KALCare.com (Kalbestore.com)</a>,{' '}
              <a href="https://www.ciputra.com/?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank" rel="noreferrer">Ciputra Group</a>, {' '} and {' '}<a href="https://www.reddoorz.com/?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank" rel="noreferrer">RedDoorz</a>. 
              Currently, I'm focused on driving digital success at {' '} <a href="https://id.hm.com/id_id/?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank" rel="noreferrer">H&M</a>, where I specialize in performance marketing and SEO implementation. My role involves optimizing online visibility, enhancing user engagement, and improving conversion rates across H&M's digital platforms. 
              Additionally, I serve as a <b>Startup Mentor & Instructor</b> at {' '} <a href="https://grow.google/intl/id_id/bangkit/?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank" rel="noreferrer">Bangkit Academy led by Google, Tokopedia, Gojek, & Traveloka</a> {' '}, where I share my expertise to nurture the next generation of tech talent.
            </p>

            <p>
              I also bring a wealth of mentoring experience, particularly in guiding individuals through the ever-evolving world of SEO (Search Engine Optimization). 
              My mission is to help you master everything from keyword research to content strategy, empowering you to carve out your unique presence in the digital landscape.
              {' '}
              <a href="https://talent.usedeall.com/mentoring/candra-reza-prasetya-gannes-345?utm_source=gannes.my.id&utm_medium=Clicks" target="_blank">
              Let's work together to elevate your skills
              </a>{' '}. Explore a few of my success stories, and together, we'll carve out your presence in the digital landscape!
            </p>

            <p>
              Recently, I launched a {' '}
              <a href="https://www.inikisahku.com/?utm_source=gannes.my.id&utm_medium=Clicks">
                mental health-sharing platform
              </a>{' '} 
              designed to be a safe space to share your stories and connect with others. 
              Let's collaborate and take your skills to the next level, explore some of my success stories, and make your digital journey successful!
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
